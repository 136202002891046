import "./bootstrap";
import "../css/app.css";

import {resolvePageComponent} from "laravel-vite-plugin/inertia-helpers";
import {createApp, h} from "vue";
import {createInertiaApp, Link} from "@inertiajs/inertia-vue3";
import {InertiaProgress} from "@inertiajs/progress";
import VueGtag from "vue-gtag";

// Vue Toastification
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import * as Sentry from "@sentry/vue";

const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./pages/${name}.vue`,
            import.meta.glob("./pages/**/*.vue")
        ),
    setup({el, app, props, plugin}) {
        var vueApp = createApp({render: () => h(app, props)}).use(plugin);

        vueApp.use(Toast, {
            transition: "Vue-Toastification__bounce",
            maxToasts: 20,
            newestOnTop: true,
        });

        if (process.env.NODE_ENV === "production") {
            vueApp.use(VueGtag, {
                // TODO: move to config env
                config: {id: "G-JGYFK1X9H2"},
            });
        }

        Sentry.init({
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            app,
            integrations: [
                Sentry.replayIntegration(),
            ],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

        return vueApp
            .mixin({methods: {route}})
            .component("Link", Link)
            .mount(el);
    },
});

InertiaProgress.init({color: "#4B5563"});
